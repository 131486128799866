<template>
    <div class="kanban">
        <marketing_hd />
        <!-- <div class="hd">
            <div class="title">智能营销</div>
            <div class="tis">
                点赞、关注、评论、私信等多场景智能营销触达，实时抖音获客、营销、用户运营的自动化、智能化。
            </div>
        </div> -->
        <div class="kanban_main">
            <div class="kanban_card" v-for="(item, index) in list" :key="index">
                <div class="list">
                    <div class="switch">
                        <div class="switch_left">
                            <img :src="item.icon" width="40px" alt="" />
                            <div class="title">{{ item.title }}</div>
                        </div>
                        <div class="switch_right">
                            <el-switch v-model="item.switch"> </el-switch>
                        </div>
                    </div>
                    <div class="message tis">
                        {{ item.message }}
                    </div>
                    <div class="tis">
                        <i class="iconfont icon-keshangxian"></i>
                        {{ item.open_message }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import marketing_hd from "./components/marketing_hd.vue";
export default {
    name: "marketing_index",
    components: { marketing_hd },
    data() {
        return {
            list: [
                {
                    title: "评论自动回复",
                    open_message: "当前抖音号支持使用",
                    message:
                        "用户给您短视频作品评论时，可配置关键词，给用户自动回复评论。",
                    type: "comment",
                    switch: 0,
                    icon: "https://hotkeypic.oss-cn-shanghai.aliyuncs.com/hotkey/yingxiao/pinglun.png",
                },
                {
                    title: "私信自动回复",
                    open_message: "经过视频授权的抖音号支持使用",
                    message: "用户给您授权账号发送私信时，自动给用户回复私信",
                    type: "private_sms",
                    switch: 0,
                    icon: "https://hotkeypic.oss-cn-shanghai.aliyuncs.com/hotkey/yingxiao/sixin.png",
                },
                {
                    title: "关注自动回复",
                    message: "用户关注您授权的账号时，自动给用户发送一条私信",
                    open_message: "经过视频授权的抖音号支持使用",
                    type: "follow",
                    switch: 0,
                    icon: "https://hotkeypic.oss-cn-shanghai.aliyuncs.com/hotkey/yingxiao/guanzhu.png",
                },
                {
                    title: "点赞自动回复",
                    open_message: "经过视频授权的抖音号支持使用",
                    message: "这是一条描述信息，这时一条描述信息，这时",
                    type: "like",
                    switch: 0,
                    icon: "https://hotkeypic.oss-cn-shanghai.aliyuncs.com/hotkey/yingxiao/dianzan.png",
                },
            ],
        };
    },

    mounted() {},

    methods: {},
};
</script>

<style lang="less" scoped>
.kanban {
    // padding: 12px 20px;
}
.kanban_main {
    background-color: #fff;
    margin-top: 13px;
    display: flex;
    flex-wrap: wrap;
    .kanban_card {
        padding: 15px;
        flex: 1;
        min-width: 340px;
        .list {
            padding: 36px;
            background-color: #f5f6f8;
            border-radius: 8px;
        }
    }

    .tis {
        font-size: 12px;
        color: #8590b3;
        margin-top: 14px;
    }
    .message {
        height: 40px;
    }
}
.switch {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .switch_left {
        display: flex;
        align-items: center;
        .title {
            margin-left: 10px;
        }
    }
}
</style>